const AppmakerWebSdk = {

    debug: false,
    log: function (key, params) {
        if (AppmakerWebSdk.debug) {
            console.log(key, params);
        }
    },

    isAppmakerWebview: function () {
        const isAppmakerWebview = !!window.appmakerWebview;
        if (!isAppmakerWebview) {
            console.warn('AppmakerWebSdk: Not running inside Appmaker Webview');
        }
        return isAppmakerWebview;
    },

    getPlatform: function () {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            return window?.appmakerWebview?.platform;
        }
    },

    openCart: function () {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            window.appmaker.appmakerAction('OPEN_CART');
        }
    },

    openProductById: function (productId) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            AppmakerWebSdk.log('openProductById', productId);
            window.appmaker.appmakerAction('OPEN_PRODUCT', { id: productId });
        }
    },

    openProductByHandle: function (productHandle) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            AppmakerWebSdk.log('openProductByHandle', productHandle);
            window.appmaker.appmakerAction('OPEN_PRODUCT', { productHandle });
        }
    },


    openCollectionById: function (collectionId) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            AppmakerWebSdk.log('openCollectionById', collectionId);
            window.appmaker.appmakerAction('OPEN_COLLECTION', { id: collectionId });
        }
    },

    openCollectionByHandle: function (collectionHandle) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            AppmakerWebSdk.log('openCollectionByHandle', collectionHandle);
            window.appmaker.appmakerAction('OPEN_COLLECTION', { handle: collectionHandle });
        }
    },

    applyCoupon: function (couponCode, { goBackAfterApply = false } = {}) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            AppmakerWebSdk.log('applyCoupon', couponCode);
            window.appmaker.appmakerAction('APPLY_COUPON', { coupon: couponCode, goBackAfterApply });
        }
    },

    removeCoupon: function (couponCode) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            AppmakerWebSdk.log('removeCoupon', couponCode);
            window.appmaker.appmakerAction('REMOVE_COUPON', { coupon: couponCode });
        }
    },


    addProductToCart: function ({ product, variant, quantity, customAttributes }) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            AppmakerWebSdk.log('addProductToCart', { product, variant, quantity, customAttributes });
            window.appmaker.appmakerAction('ADD_TO_CART_V2', {
                product: { node: product },
                variant: { node: variant },
                quantity,
                customAttributes: customAttributes,
            });
        }
    },

    removeProductFromCart: function ({ lineItemId, product, variant, updateCartPageStateRequired }) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            AppmakerWebSdk.log('removeProductFromCart', { lineItemId, product, variant, updateCartPageStateRequired });
            window.appmaker.appmakerAction('UPDATE_CART_V2', {
                lineItemId,
                product: { node: product },
                variant: { node: variant },
                quantity: 0,
                updateCartPageStateRequired: updateCartPageStateRequired
            });
        }
    },

    updateProductQuantityInCart: function ({ lineItemId, product, variant, quantity, updateCartPageStateRequired }) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            AppmakerWebSdk.log('updateProductQuantityInCart', { lineItemId, product, variant, quantity, updateCartPageStateRequired });
            window.appmaker.appmakerAction('UPDATE_CART_V2', {
                lineItemId,
                product: { node: product },
                variant: { node: variant },
                quantity,
                updateCartPageStateRequired: updateCartPageStateRequired
            });
        }
    },


    clearCart: function () {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            window.appmaker.appmakerAction('CLEAR_CART');
        }
    },

    setLineItemProperties: function ({ lineItemId, product, variant, properties }) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            window.appmaker.appmakerAction('UPDATE_CART_V2', {
                lineItemId,
                product: { node: product },
                variant: { node: variant },
                quantity: 1,
                customAttributes: properties,
            });
        }
    },
    openInAppPage: function ({ pageId, title, replacePage = false }) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            window.appmaker.appmakerAction('OPEN_INAPP_PAGE', {
                pageId,
                title,
                replacePage,
            });
        }
    },

    openHome: function ({ replacePage = false, } = {}) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            window.appmaker.appmakerAction('OPEN_HOME', {
                replacePage,
            });
        }
    },

    showMessage: function ({ title }) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            window.appmaker.appmakerAction('SHOW_MESSAGE', {
                title
            });
        }
    },

    goBack: function () {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            window.appmaker.appmakerAction('GO_BACK');
        }
    },

    copyToClipboard: function ({ text }) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            window.appmaker.postMessage({
                appmakerAction: {
                    action: 'COPY_TO_CLIPBOARD',
                    text,
                },
            });
        }
    },

    openCheckout: function () {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            window.appmaker.appmakerAction('START_CHECKOUT');
        }
    },

    openSearch: function () {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            window.appmaker.appmakerAction('OPEN_SEARCH');
        }
    },    
    updateCartCustomAttributes: function ({ cartCustomAttributes }) {
        if (AppmakerWebSdk.isAppmakerWebview()) {
            window.appmaker.appmakerAction('UPDATE_CART_CUSTOM_ATTRIBUTES', {
                cartCustomAttributes
            });
        }
    }
}

export default AppmakerWebSdk;